var mobileMenuOpened = false;

$(function() {

  $('.mobile-menu').on('show.bs.collapse', function () {
    mobileMenuOpened = true;
    $("body").addClass("mobile-menu-opened");
    $('.c-hamburger').addClass('is-active');
    $('.search-panel').collapse('hide');
  })
  $('.mobile-menu').on('hidden.bs.collapse', function () {
    mobileMenuOpened = false;
    $("body").removeClass("mobile-menu-opened");
    $('.c-hamburger').removeClass('is-active');
  })
  
  
  $('.search-panel').on('show.bs.collapse', function () {
    $('.mobile-menu').collapse('hide');
  })
  $('.mobile-menu .modal-header a').on("click", function(){
    $('.mobile-menu').collapse('hide');
  });

  
  $('button.btn-personal-keyword').on("click", function(event){
    // Avoid following the href location when clicking
    event.preventDefault(); 
    // Avoid having the menu to close when clicking
    event.stopPropagation();
    
    
    var $this = $(this),
        bloggroupId = $this.data("bg-id"),
        keywordId = $this.data("keyword-id"),
        isSelected = $this.hasClass("selected");

    $this.toggleClass('selected');
    $this.find('i.fa').toggleClass('fa-heart');
    $this.find('i.fa').toggleClass('fa-heart-o');
    
    $.ajax({
      type: "POST",
      url: "/?cmd=toggle-selected-keyword",
      dataType: "json",
      data: {
        blogGroupID: bloggroupId,
        keywordId: keywordId,
        isSelected: isSelected
      },
      success: function(data) {
        var keywordButtons = $(".btn-personal-keyword[data-keyword-id='" + keywordId + "']");
        if (data.response.isSelected) {
          keywordButtons.addClass('selected');
          keywordButtons.find('i.fa').removeClass('fa-heart-o');
          keywordButtons.find('i.fa').addClass('fa-heart');
        }
        else {
          keywordButtons.removeClass('selected');
          keywordButtons.find('i.fa').removeClass('fa-heart');
          keywordButtons.find('i.fa').addClass('fa-heart-o');
        }
      },
      error: function(data) {
        if (data.status == 401) {
          window.location.replace('/');
        }
        else {
          $this.toggleClass('selected');
          $this.find('i.fa').toggleClass('fa-heart');
          $this.find('i.fa').toggleClass('fa-heart-o');
        }
      }
    });

  });

  $(window).on("resize", function() {
    if (windowWidth != $(window).width()) {
      $('.mobile-menu').collapse('hide');
      windowWidth = $(window).width();
    }
    
    $('.slim-scroll').each(function() {
      var container = $(this).closest('.slim-scroll-container');
      $(this).slimScroll({destroy: true}).height("auto");
      $(this).slimScroll({
        height: container.height() - container.css('margin-top').replace("px", "")
      });
    });
  });
  
  $('.edit-form-button').on("click", function() {
    var form = $(this).closest("form");
    form.data("enabled", true);
    
    //formsDisabled = !formsDisabled;
    jQuery.each(form.find('.hidden-rw-form-field'), function( i, val ) {
        $(val).hide();
    });
    jQuery.each(form.find('.hidden-ro-form-field'), function( i, val ) {
        $(val).show();
    });
    jQuery.each(form.find('.disabled-form-field'), function( i, val ) {
        $(val).removeAttr('disabled');
    });
  });
  
  jQuery.each($('.hidden-ro-form-field'), function( i, val ) {
    $(val).hide();
  });
  
  $( "#menu-search-btn" ).on("click", function(e) {
    e.stopPropagation();
    e.preventDefault();
    e.stopImmediatePropagation();
    var shouldOpen = !$(this).parent().hasClass( "open" );
    // If a menu is already open we close it
    // opening the one you clicked on
    if(shouldOpen) {
      $(this).parent().parent().children(".dropdown").removeClass('open');
      $(this).parent().addClass('open');  
      $("#menu-top-query").focus();
    } else {
      $(this).parent().removeClass('open');        
    }
  });
});

function beforeSubmitJsonForm(data, $form, options) 
{
  var loading = $form.find(".loading-indicator"),
      validation = $form.find(".validation").empty().hide();
  
  if (loading) {
    loading.show();
  }
  
  $('p.input-error').remove();
  $('.input-error').removeClass('input-error');
}

function resetViewPort() 
{
  $(window).scrollTop(0);
}

function processJsonForm(data, statusText, xhr, $form) 
{
  var inputs = $form.find("input"),
      loading = $form.find(".loading-indicator"),
      validation = $form.find(".validation").empty().hide(),
      formSuccessMessage = $form.find('.form-success-message');
    
  $('.messages').empty();
  formSuccessMessage.addClass('hidden');
  
  if (data.status == 'success')
  {
    // Update form fields
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        var val = data[key];
        $("[name='" + key + "']").val(val).trigger("change");
        
        if (key == "userCountry") {
          if (val === "US" || val === "United States") {
            $('.state-block').removeClass('hidden').addClass('hidden-ro-form-field').show();
            $('.province-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
            $('.region-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
          }
          else if (val === "CA" || val === "Canada") {
            $('.state-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
            $('.province-block').removeClass('hidden').addClass('hidden-ro-form-field').show();
            $('.region-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
          }
          else {
            $('.state-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
            $('.province-block').addClass('hidden').removeClass('hidden-ro-form-field').hide();
            $('.region-block').removeClass('hidden').addClass('hidden-ro-form-field').show();
          }
        }
      }
    }
    $('form.dirty').removeClass('dirty');
    $form.removeClass('required-to-be-changed');
    
    // Switch form to the read only mode
    disableForm($form);
  }
  else if (data.status == 'message')
  {
    $form.removeClass('required-to-be-changed');
  }
  else if (data.status == 'validationError')
  {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        var input = $("[name='" + key + "']");//, #select2-" + key + "-container
        var val = data[key];
        input.addClass('input-error');
        input.closest('.form-group').addClass('input-error');
        
        var label = input.prev('label');
        if (!label) {
          label = input;
        }
        label.before( '<p class="input-error">' + val + '</p>' );
      }
    }
  }
  
  if (data.message) {
    if (data.status == 'success') {
      formSuccessMessage.removeClass('hidden');
      formSuccessMessage
          .html($("<p></p>").html('<i class="fa fa-check text-info"></i> ' + data.message))
          .show();
    }
    else {
      validation
          .html($("<p></p>").html(data.message))
          .show();
    }
  }
  
  resetViewPort();
  
  if (data.redirect) {
    setTimeout(function() {
      window.location.replace(data.redirect);
    }, 1500);
  } 
  else 
  {
    if (loading) {
      loading.hide();
    }
    
    if (data.reload) {
      setTimeout(function() {
        window.location.reload();
      }, 1500);
    }
  }
}

function disableForm($form) {
    var form = $form;
    jQuery.each(form.find('.hidden-rw-form-field'), function( i, val ) {
        $(val).show();
    });
    jQuery.each(form.find('.hidden-ro-form-field'), function( i, val ) {
        $(val).hide();
    });
    jQuery.each(form.find('.disabled-form-field'), function( i, val ) {
        $(val).attr('disabled', 'disabled');
    });
}

$('.search-form').on("submit", function() {
  var form = $(this);
  var loc = form.find('input[name=domain]')[0].value;
  if (loc.length > 1 && loc.charAt(loc.length-1) != '/') {
    loc = loc + '/';
  }
  var query = '?query=' + encodeURIComponent(form.find('input[name=query]')[0].value) + '';
  loc = loc + query;
  window.location = loc;
  return false;
});

$('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
  // Avoid following the href location when clicking
  // Avoid having the menu to close when clicking
  event.stopPropagation();
  event.preventDefault();
  event.stopImmediatePropagation();
  var shouldOpen = !$(this).parent().hasClass( "open" );
  // If a menu is already open we close it
  $('ul.dropdown-menu [data-toggle=dropdown]').parent().removeClass('open');
  // opening the one you clicked on
  if(shouldOpen)
  {
    $(this).parent().addClass('open');  
  }
});

var lastScrollTop = 0;
var fixedNavbarIsShown = false;
var navbarScrollingMode = false;
var oldHrWidth = $('#widehr').width();
var oldmarginLeft = $('#widehr').css('marginLeft');
var oldmarginRight = $('#widehr').css('marginRight');
var oldpaddingLeft = $('#widehr').css('paddingLeft');
var oldpaddingRight = $('#widehr').css('paddingRight');
var menuItem = $('#topics-menu-id');
var sticky = menuItem.offsetTop;
var mainContainer =  $('#mainheaderid');
var mainContainerHeight = mainContainer.length>0? mainContainer.outerHeight(true) : 0;
var mainContentDiv =  $('#main-content');
var mainContentDivHeight = mainContentDiv.length>0? mainContentDiv.outerHeight(true) : 0;
function scroll() {
  if (mobileMenuOpened) {
    return;
  }
  var mainNavBar = $('#main-navbar');
  var mainNavBarHeight = mainNavBar.length>0? mainNavBar.outerHeight(true) : 0;
  var menuItemHeight = menuItem.length>0? menuItem.outerHeight(true) : 0;
  var windowWidth = $(window).width();
  var currentScrollTop = $(window).scrollTop();
  
  var navBarHeaderSmall = 1;
//  var topBanner = $('#top-banner');
  var mainDiv = $('#main-div');
  
  var mainDivHeight = mainDiv.length>0? mainDiv.outerHeight(true) : 0;
  var countryPrompt = $('#country-prompt-id');
  var countryPromptHeight = countryPrompt.length>0? countryPrompt.outerHeight(true) : 0;
  var extraPadding =0;
  var midDiv = $('#middle-div');
  var bannerDiv = $('.webinar-banner');
  var bannerDivHeight = bannerDiv.length>0? bannerDiv.outerHeight(true) : 0;
  var whiteBannerDiv = $('.site-header-block');
  var whiteBannerDivHeight = whiteBannerDiv.length>0? whiteBannerDiv.outerHeight(true) : 0;
  var topBannerHeight = topBanner.length>0? topBanner.outerHeight(true) : 0;
  var midDivHeight = midDiv.length>0? midDiv.outerHeight(true) : 0;
   if(countryPromptHeight == 0) {
     extraPadding = 50;
   }
  if(bannerDivHeight <110) {
    bannerDivHeight =300;
  }
  
  var navBarHeaderLarge = mainDivHeight;
  var totalHeaderHeight = topBannerHeight;
  var navheight = $('#main-navbar').outerHeight();
  if (windowWidth >= 561) {
    totalHeaderHeight += navBarHeaderLarge; 
  }
  else {
    totalHeaderHeight += navBarHeaderSmall;
  }
  var totalHeightToScroll = countryPromptHeight+ topBannerHeight+mainDivHeight;
  
  // Downscroll or wide screen (always show fixed navbar)
  if (currentScrollTop < lastScrollTop || $(window).width() >= 561) {
    if (currentScrollTop > totalHeightToScroll) {
      //we have reached the menu bpttom border
       if(currentScrollTop > lastScrollTop ) {
         //we are scrolling down
      fixedNavbarIsShown = true;
        mainNavBar.addClass('navbar-fixed-top');
        $('#widehr').width("200vw");
        $('#widehr').css('padding-right', '3000px');
        $('#widehr').css('margin-right', '-3000px');
        $('#widehr').css('padding-left', '3000px');
        $('#widehr').css('margin-left', '-3000px');
        $('#widehr').css('position', 'left');
        $('#widehr').css('width', 'calc(((100vw - 100%) / 2) + 100%)');
        $('#widehr').css('position', 'relative');
        
//        $('#top-banner').removeClass('background-gray');
        
        
        $('ul.dotsbetween').addClass('hidden');//.removeClass('ul.dotsbetween').hide();
        $('.large-banner-panel').css('background-color','white');
        var padValue = mainDivHeight/2;
        //$('#main-content').css('padding-top', mainDivHeight+216);
        $('#main-content').css('padding-top', mainDivHeight+216);
       }
       else if(currentScrollTop < lastScrollTop && currentScrollTop > (mainContainerHeight+totalHeightToScroll-menuItemHeight+5)) {
         //we are scrolling up
         fixedNavbarIsShown = true;
           mainNavBar.addClass('navbar-fixed-top');
           $('#widehr').width("200vw");
           $('#widehr').css('padding-right', '3000px');
           $('#widehr').css('margin-right', '-3000px');
           $('#widehr').css('padding-left', '3000px');
           $('#widehr').css('margin-left', '-3000px');
           $('#widehr').css('position', 'left');
           $('#widehr').css('width', 'calc(((100vw - 100%) / 2) + 100%)');
           $('#widehr').css('position', 'relative');
           
//           $('#top-banner').removeClass('background-gray');
           
           
           $('ul.dotsbetween').addClass('hidden');//.removeClass('ul.dotsbetween').hide();
           $('.large-banner-panel').css('background-color','white');
           
           $('#main-content').css('padding-top', 0);   
       }
       else {
           // Hide fixed navbar
         
         fixedNavbarIsShown = false;
           mainNavBar.removeClass('navbar-fixed-top');
           $('#widehr').width(oldHrWidth);
           $('#main-content').css('padding-top', 0);
           $('#widehr').css('padding-left', oldpaddingLeft);
           $('#widehr').css('padding-right', oldpaddingRight);
           $('#widehr').css('margin-left', oldmarginLeft);
           $('#widehr').css('margin-right', oldmarginRight);
//           $('#top-banner').addClass('background-gray');
           $('ul.dotsbetween').removeClass('hidden');
           $('.large-banner-panel').css('background-color','#F2F2F2');
       }
        
    } else {
        // Hide fixed navbar
      fixedNavbarIsShown = false;
        mainNavBar.removeClass('navbar-fixed-top');
        $('#widehr').width(oldHrWidth);
        $('#main-content').css('padding-top', 0);
        $('#widehr').css('padding-left', oldpaddingLeft);
        $('#widehr').css('padding-right', oldpaddingRight);
        $('#widehr').css('margin-left', oldmarginLeft);
        $('#widehr').css('margin-right', oldmarginRight);
//        $('#top-banner').addClass('background-gray');
        $('ul.dotsbetween').removeClass('hidden');
        $('.large-banner-panel').css('background-color','#F2F2F2');
    }
    
    if ($(window).width() >= 561) {
      mainNavBar.css('top', 0);
  //    mainNavBar.css('scroll-behavior','smooth');
    }
    else {
        // Move fixed navbar
        var newOffset = 0;
        if (fixedNavbarIsShown && navbarScrollingMode) {
          newOffset = mainNavBar.get(0).offsetTop + lastScrollTop - currentScrollTop;
          if (newOffset > 0) {
            newOffset = 0;
          }
          else if (newOffset < -currentScrollTop) {
            newOffset = -currentScrollTop;
          }
        }
        $('#main-navbar.navbar-fixed-top').css('top', newOffset);
    }
    
  } else {
    // Upscroll narrow screen 
    // If at least part of fixed navbar is displayed, scroll it up first
    var mainNavBarOffsetBottom = mainNavBar.get(0).offsetTop + mainNavBar.get(0).offsetHeight;
    if (mainNavBarOffsetBottom > 0)// $(window).height() 
    {
      var newOffset = mainNavBar.get(0).offsetTop + lastScrollTop - currentScrollTop;
      if (newOffset > 0) {
        newOffset = 0;
      }
      else if (newOffset < -currentScrollTop) {
        newOffset = -currentScrollTop;
      }
          
      $('#main-navbar.navbar-fixed-top').css('top', newOffset);
      navbarScrollingMode = true;
    }
    /*
    else 
    {
        // Hide fixed navbar if it is already scrolled up fully
        fixedNavbarIsShown = false;
        navbarScrollingMode = false;
        $('.search-panel').collapse('hide');
        $('.mobile-menu').collapse('hide');
        mainNavBar.removeClass('navbar-fixed-top');
        mainNavBar.css('top', 0);
        $('#main-content').css('padding-top', 0);
    } 
    */ 
  }
  lastScrollTop = currentScrollTop;
}
$(document).ready(function(){


    var sticky = new Waypoint.Sticky({
      element: $('.sticky')[0]
  });

  });

//document.onscroll = scroll;
$(function() {

  var options = {
    dataType:  'json', 
    beforeSubmit: beforeSubmitJsonForm,  // pre-submit callback
    success: processJsonForm,            // post-submit callback 

    // other available options:
    //target:        '#output1',   // target element(s) to be updated with server response 
    //success:       showResponse  // post-submit callback  
    //url:       url         // override for form's 'action' attribute 
    //type:      type        // 'get' or 'post', override for form's 'method' attribute 
    //dataType:  null        // 'xml', 'script', or 'json' (expected server response type) 
    //clearForm: true        // clear all form fields after successful submit 
    //resetForm: true        // reset the form after successful submit 

    // $.ajax options can be used here too, for example: 
    //timeout:   3000 
  };
   
  // bind 'myForm' and provide a simple callback function
  $('form.ajax-form').ajaxForm(options); 
  
  $('a').on("click", function (e) {
    $('.messages').empty();
    $('form.ajax-form.dirty').each(function(index) {
      validation = $(this).find(".validation");
      validation
        .html($("<p></p>").html("Save or cancel your changes."))
        .show();              
      e.preventDefault();
      e.stopPropagation();
    });
    $('#password-preferences-form.required-to-be-changed').each(function(index) {
      validation = $(this).find(".validation");
      validation
        .html($("<p></p>").html("Please enter a new password."))
        .show();              
      e.preventDefault();
      e.stopPropagation();
    });
  });
  
  $('form.ajax-form.detect-changes').areYouSure();

});

    $("#siteMetricsSpinner").hide();
    $(document).ready(function() {
    $("#siteMetricsA").click(function() {
            $("#siteMetricsSpinner").show();
        });
    });
