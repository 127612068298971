var secondClick = false;

function getValidationElement(element)
{
  var tag = element.tagName;
  if(tag != null)
  {
    // Making it always upper case just in case
    tag = tag.toUpperCase();
  }
  var shouldCheck = false;
  if(tag == 'A')
  {
    shouldCheck = true;
  }
  else if(tag == 'INPUT')
  {
    var type = element.getAttribute("type");
    if(type != null)
    {
    // Making it always upper case just in case        
      type = type.toUpperCase();
    }
    if(type == 'SUBMIT' || type == 'BUTTON' || type == 'IMAGE')
    {
      shouldCheck = true;      
    }
  }
  else if(tag == 'BUTTON')
  {
    shouldCheck = true;
  }
  if(shouldCheck)
  {
    return element;
  }
  else
  {
    if(element.parentElement == null)
    {
      return null;
    }
    else
    {
      return getValidationElement(element.parentElement);
    }
  }
}

// Will only be added when it should validate the email
if(shouldValidateEmail)
{
  document.addEventListener('click', function (event) 
  {
    var result = true;
    var validationElement = getValidationElement(event.target);
    if(validationElement != null)
    {
      result = processConfirmatioNEvent(validationElement, event);
    }
    return result;
  }, 
  true);
}
  
function processConfirmatioNEvent(that, event) 
{
  if(!secondClick) 
  {
    event.stopPropagation();
    var shouldConfirm = that.getAttribute("data-confirmation");
    if(shouldConfirm == "false")
    {
      var discard = "discard";
      validationCall(that, discard, discard, discard);
    }
    else
    {
      obtainRecaptcha(that, event);
    }    
    secondClick = true;
    return false; 
  } 
  return true;
}

function validationCall(that, token, action, pageType)
{
  var dataToSend = {"token": token, "action":action, "pageType":pageType};
  $.extend(dataToSend, validateParams);
  $.ajax({
    type: "POST",
    url: validateEmailURL,
    data: dataToSend,
    dataType: 'json',
    success: function(data) 
    {
      that.click();
    },
    error: function(data) 
    {
      console.log(data);
      // TODO: what we should do with this
      //that.click();
    }
  });    
}
  
function obtainRecaptcha(that, event)
{
  event.preventDefault();    
 
  var currentItem = that;
  var actionName = that.getAttribute("data-nameact");
  if(!actionName)
  {
    var tagName = that.tagName;
    if(tagName == 'A')
    {
      tagName = 'LINK';
    }
    
    if(tagName == 'INPUT')
    {
      // Only Do prevent default on inputs for submits
      var name = that.value;
      if(name)
      {
        actionName = name.replace(/[^a-zA-Z]/g, "");        
      }
      else
      {
        actionName = 'Unkown_INPUT';     
      }
    }
    else
    {
      actionName = 'Unkown_' + tagName;      
    }
  }
  if(shouldUseRecaptcha) {
    grecaptcha.ready(function() 
    {    
      grecaptcha.execute(grecaptchav3_sitekey, {action: actionName}).then(function(token) 
      { 
        validationCall(that, token, actionName, pageType);
      });
      
    });
  } else { 
    validationCall(that, "none", actionName, pageType);    
  }
}


